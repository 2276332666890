<template>
    <div v-if="$store.state.isModalOpenTwo" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered" ref="modalRef">
            <div class="modal-content p-2">
                <div v-for="(item, index) in saleReturnData" :key="index">
                    <div class="d-flex justify-content-center align-items-center p-1">
                        <span class="text-center">{{ item.bill_number }}</span>
                        <button class="btn btn-secondary btn-sm curosr-pointer mx-1"
                            @click="goToPrint(item.id)"
                        >
                            <i class="fas fa-print"></i>
                        </button>
                    </div>
                </div>

                <div class="col-12 text-center mt-2">
                    <button @click.prevent="onClickClose" class="btn btn-outline-secondary waves-effect">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import handlePurchaseAndSalesReturn from '@/services/modules/purchaseSalesReturn';
import salesReturnHelper from "@/services/utils/pdf/salesReturnHelper";

const { fetchSalesReturn } = handlePurchaseAndSalesReturn();
const { generatePdf } = salesReturnHelper();

const props = defineProps(['saleReturnData', 'companyInfo', 'billType']);
const $store = useStore();
const $route = useRoute();
const modalRef = ref(null);
const salesReturn = ref({});

const onClickClose = () => {
    $store.state.isModalOpenTwo = false;
}

const goToPrint = (id) => {
    const query = `?company_id=${$route.params.companyId}`;
    fetchSalesReturn(id, query).then((res) => {
        salesReturn.value = res.data
    }).then( () => {
        generatePdf(props.companyInfo, salesReturn.value, props.billType)
    }).catch( (err) => {
        console.log(err);
    })
}
</script>